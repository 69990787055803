<template>
  <div class='under-maintenance h-100 d-flex flex-column align-items-center justify-content-center'>
    <div>
      <img
        src='https://files-hofacil.s3.sa-east-1.amazonaws.com/1694555705618'
        title='Logo HoFácil Web'
        alt='Logo HoFácil Web' height='250' width='250'
      />
    </div>

    <div class='content'>
      <h2>Manutenção Programada</h2>
      <h4>Estamos realizando algumas atualizações para atendê-los melhor. Retornaremos em alguns instantes.</h4>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import api from '@/utils/api'

export default {
  name: 'UnderMaintenance',
  data() {
    return {
      interval: null
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Minha conta',
      subPage: {
        name: 'Minhas preferências',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    await this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    async startTimer() {
      try {
        this.interval = setInterval(async () => {
          fetch(`${process.env.VUE_APP_BASE_URL}/health`, {
            method: 'GET'
          })
          .then(async response => {
            await this.$router.go(-1)
          })
          .catch(err => {
          })
        }, 30000)
      } catch (e) {

      }
    }
  }
}
</script>

<style lang='scss'>
.under-maintenance {
  background-color: #00537b;
  color: #FFF;
  text-align: center;
  min-height: 100vh;
}
</style>
